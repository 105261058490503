import { Pagination, Stack } from "@mui/material";
import { AppContext } from "context/AppContext";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getInvoices } from "store/invoicesSlice";
import { getLogs } from "store/logsSlice";

const Paginate = ({ count, variant }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch()
  const { searchForLogsQuery, searchForInvoicesQuery } = useContext(AppContext)

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (variant === "invoices") {
      dispatch(getInvoices({ count: page - 1, search: searchForInvoicesQuery }))
    } else {
      dispatch(getLogs({ count: page - 1, search: searchForLogsQuery }))
    }
  }, [page, dispatch, searchForLogsQuery, variant, searchForInvoicesQuery])

  return (
    <Stack spacing={2}>
      <Pagination count={count} variant="outlined" color="primary" onChange={handleChange} />
    </Stack>
  )
}

export default Paginate