import { AttachEmail, EditRounded, LocalActivityRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import LoadButton from "components/LoadButton/LoadButton";
import { AppContext } from "context/AppContext";
import { handleAlert } from "functions/handleAlert";
import { PrimaryButton } from "mui/PrimaryButton";
import { SecondaryButton } from "mui/SecondaryButton";
import { useContext, useState } from "react";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./InvoiceButtons.module.scss";
import Cookies from "js-cookie";
import VoucherPopup from "../../modals/CreditVoucher";
import SendEmailPopup from "modals/SendEmailPopup";

const InvoiceClientButtons = ({ invoice }) => {
  const { handleOpenViewActivitiesModal } = useContext(AppContext)
  // const { token } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [voucherLoading, setLvoucherLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleEmailOpen = () => {
    setIsEmailModalOpen(true);
  };

  const handleEmailClose = () => {
    setIsEmailModalOpen(false);
  };

  const token = Cookies.get('invoicesBTB_TOKEN');

  const handleSendEmail = async (data) => {
    setLoading(true);
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/sendInvoiceEmail`, {
      invoice_id: invoice._id,
      ...data,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      handleAlert("Email Sent Successfully", "success");
    }).catch((err) => {
      handleAlert("Failed to Send Email", "error");
    });
    setLoading(false);
  };

  const handleFetchDownloadPDF = async () => {
    setLoading(true)
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/generateFormPdf`, invoice, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Invoice #${invoice.invoiceNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      handleAlert("PDF is Downloaded Successfully", "success")
    }).catch((err) => {
      try {
        handleAlert(err.response.data.message, "error")
      } catch (error) {
        handleAlert("error occurs", "error")
      }
    })
    console.log("invoice");
    setLoading(false)
  }

  const handleFetchDownloadCreditVoucherPDF = async (data) => {
    setLvoucherLoading(true)
    // console.log(process.env.REACT_APP_TOKEN_COOKIE_NAME);
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/generateVoucher/${invoice._id}`,data, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Invoice #${invoice.invoiceNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      handleAlert("PDF is Downloaded Successfully", "success")
    }).catch((err) => {
      try {
        handleAlert(err.response.data.message, "error")
      } catch (error) {
        handleAlert("error occurs", "error")
      }
    })
    handleClose(false)
    setLvoucherLoading(false)
  }

 

  const handleViewActivities = () => {
    handleOpenViewActivitiesModal(invoice.Actions)
  }

  return (
    <Box className={`grid jcs aic g20`}>


        <LoadButton loading={loading}>
          
          <PrimaryButton onClick={handleFetchDownloadPDF} className="flex jcc aic g5">
            <BsFillFileEarmarkPdfFill />
            <Typography variant="h6">Download PDF</Typography>
          </PrimaryButton>
        </LoadButton>


        {/* <DeleteButton onClick={handleDelete} className="flex jcc aic g5">
        <DeleteRounded/>
        <Typography variant="h6">Delete</Typography>
      </DeleteButton> */}


      <SendEmailPopup
        open={isEmailModalOpen}
        handleClose={handleEmailClose}
        handleSendEmail={handleSendEmail}
      />
      <VoucherPopup open={isModalOpen} handleClose={handleClose} handleVoucher={handleFetchDownloadCreditVoucherPDF} />
    </Box>
    
  )
}

export default InvoiceClientButtons