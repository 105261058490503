import { AddRounded } from "@mui/icons-material"
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from "@mui/material"
import LoadButton from "components/LoadButton/LoadButton"
import { PrimaryButton } from "mui/PrimaryButton"
import { PrimaryTextField } from "mui/PrimaryTextField"
import { useState } from "react"
import styles from "./CreateInvoice.module.scss"

const AddNewItemForm = ({ loading, formik }) => {
  const [breakfast, setBreakfast] = useState(false)

  const handleChangeBreakfast = (e) => {
    setBreakfast(e.target.checked)
    formik.values.breakfast = e.target.checked
  }

  return (
    <Box className={`${styles.add_new_item} grid jcs aic g30`}>
      <Box className={`flex jcc aic g10`}>
        <AddRounded sx={{ color: (theme) => theme.palette.facebook }} />
        <Typography variant="h4" className={`tac`}>Add New Item</Typography>
      </Box>
      <Box className={`grid jcs aic g20`}>
        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="number"
            id="nights"
            name="nights"
            label={"Number of Nights"}
            value={formik.values.nights}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.nights && Boolean(formik.errors.nights)}
            helperText={formik.touched.nights && formik.errors.nights}
          />
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="number"
            label={"Average Price Per Night"}
            id="average"
            name="average"
            value={formik.values.average}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.average && Boolean(formik.errors.average)}
            helperText={formik.touched.average && formik.errors.average}
          />
        </Box>

        <PrimaryTextField
          fullWidth
          variant="outlined"
          type="text"
          id="guestName"
          name="guestName"
          label={"Guest Name"}
          value={formik.values.guestName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.guestName && Boolean(formik.errors.guestName)}
          helperText={formik.touched.guestName && formik.errors.guestName}
        />


        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">Check In Date</Typography>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="date"
              id="checkInDate"
              name="checkInDate"
              value={formik.values.checkInDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.checkInDate && Boolean(formik.errors.checkInDate)}
              helperText={formik.touched.checkInDate && formik.errors.checkInDate}
            />
          </Box>
          <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">Check Out Date</Typography>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="date"
              id="checkOutDate"
              name="checkOutDate"
              value={formik.values.checkOutDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.checkOutDate && Boolean(formik.errors.checkOutDate)}
              helperText={formik.touched.checkOutDate && formik.errors.checkOutDate}
            />
          </Box>
        </Box>

        <Box className={`flex jcc aic g20  ${styles.md_wrap}`}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="selection"
              name="smoking"
              id="smoking"
              value={formik.values.smoking}
              onChange={formik.handleChange}
              sx={{ flexDirection: "row !important" }}
              className="g20"
            >
              <FormControlLabel value="Smoking" control={<Radio />} label="Smoking" />
              <FormControlLabel value="Non-Smoking" control={<Radio />} label="Non-Smoking" />
            </RadioGroup>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              id="breakfast"
              name="breakfast"
              control={<Checkbox checked={breakfast} onChange={handleChangeBreakfast} />}
              label="Breakfast"
              labelPlacement="start"
            />
          </FormGroup>
        </Box>

        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            select
            id="roomType"
            name="roomType"
            value={formik.values.roomType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.roomType && Boolean(formik.errors.roomType)}
            helperText={formik.touched.roomType && formik.errors.roomType}
            SelectProps={{
              native: true,
            }}
          >
            <option value={""}>Choose Room Type</option>
            <option value={"Single"}>Single</option>
            <option value={"Double"}>Double</option>
            <option value={"Twin"}>Twin</option>
          </PrimaryTextField>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            select
            id="bedType"
            name="bedType"
            value={formik.values.bedType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bedType && Boolean(formik.errors.bedType)}
            helperText={formik.touched.bedType && formik.errors.bedType}
            SelectProps={{
              native: true,
            }}
          >
            <option value={""}>Choose Bed Type</option>
            <option value={"King Bed"}>King Bed</option>
            <option value={"Two Queen Beds"}>Two Queen Beds</option>
            <option value={"Two Double Beds"}>Two Double Beds</option>
          </PrimaryTextField>
        </Box>

        <PrimaryTextField
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          type="text"
          id="extras"
          name="extras"
          label={"Extras"}
          value={formik.values.extras}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.extras && Boolean(formik.errors.extras)}
          helperText={formik.touched.extras && formik.errors.extras}
        />
      </Box>
      <LoadButton loading={loading}>
        <PrimaryButton type={"submit"} className={`flex jcc aic g5`}>
          <Typography variant="h6">Add</Typography>
        </PrimaryButton>
      </LoadButton>
    </Box>
  )
}

export default AddNewItemForm