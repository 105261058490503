import React from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from "@mui/material";

const DuplicatePopup = ({ open, handleClose, handleDuplicate }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    handleDuplicate(data);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Duplicate Invoice</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            margin="dense"
            label="Invoice Number"
            fullWidth
            {...register("invoiceNumber", { required: "Invoice Number is required" })}
            error={!!errors.invoiceNumber}
            helperText={errors.invoiceNumber?.message}
          />
          <TextField
            margin="dense"
            label="Reference Number"
            fullWidth
            {...register("referenceNumber", { required: "Reference Number is required" })}
            error={!!errors.referenceNumber}
            helperText={errors.referenceNumber?.message}
          />
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            <Button type="submit" color="primary">Submit</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DuplicatePopup;
