import { ExpandMore, FiberManualRecordRounded } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import styles from "./HotelList.module.scss"

const HotelList = ({ title, list }) => {
  return (
    <Accordion className={`${styles.hotel_list}`}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h5" className="fw700">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={`grid jcs aic g20`}>
          {list.map((l, i) => (
            <Box key={i} className={`flex jcfs aifs g5 ${styles.item}`}>
              <FiberManualRecordRounded sx={{ color: (theme) => theme.palette.primary.main }} />
              <Typography variant="h6">{l}</Typography>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default HotelList