import { createContext, useState } from "react";
import { useParams } from "react-router-dom";

export const AppContext = createContext()

const AppProvider = ({children}) => {
  const { id } = useParams()

  //Choose Number of hotel
  const [openChooseNumberOfHotelModal , setOpenChooseNumberOfHotelModal] = useState(false)
  const [OpenChooseNumberOfDuplicateInvoices , setOpenChooseNumberOfDuplicateInvoices] = useState(false)
 

  const handleOpenChooseNumberOfHotelModal=()=>{
    setOpenChooseNumberOfHotelModal(true)
  }

  const handleCloseChooseNumberOfHotelModal=()=>{
    setOpenChooseNumberOfHotelModal(false)
  }

  const handleOpenChooseNumberOfDuplicateInvoices=()=>{
    setOpenChooseNumberOfDuplicateInvoices(true)
  }

  const handleCloseChooseNumberOfDuplicateInvoices=()=>{
    setOpenChooseNumberOfDuplicateInvoices(false)
  }

  //Delete Log
  const [openDeleteLogModal,setOpenDeleteLogModal] = useState(false)
  const [logId,setLogId] = useState(null)

  const handleCloseDeleteLogModal = ()=>{
    setOpenDeleteLogModal(false)
  }

  const handleOpenDeleteLogModal = (id)=>{
    setOpenDeleteLogModal(true)
    setLogId(id)
  }

  //Send Mail
  const [openSendMailModal,setOpenSendMailModal] = useState(false)
  
  const handleCloseSendMailModal = ()=>{
    setOpenSendMailModal(false)
  }

  const handleOpenSendMailModal = (id)=>{
    setOpenSendMailModal(true)
    setLogId(id)
  }

  //Choose Hotel
  const [openChooseHotelDialog , setOpenChooseHotelDialog] = useState(false)
  const [hotelIndex , setHotelIndex] = useState(null)

  const handleCloseChooseHotelDialog=()=>{
    setOpenChooseHotelDialog(false)
  }

  const handleOpenChooseHotelDialog=(index)=>{
    setOpenChooseHotelDialog(true)
    setHotelIndex(index)
  }

  //Edit Log
  const [logData,setLogData] = useState(null)


  //Search For Logs
  const [searchForLogsQuery,setSearchForLogsQuery] = useState("")


  //Download Log
  const [openDownloadLogModal,setOpenDownloadLogModal] = useState(false)

  const handleOpenDownloadLogModal=(id)=>{
    setOpenDownloadLogModal(true)
    setLogId(id)
  }

  const handleCloseDownloadLogModal=()=>{
    setOpenDownloadLogModal(false)
  }

  //Edit Hotel
  const [openEditHotelModal,setOpenEditHotelModal] = useState(false)
  const [hotelData,setHotelData] = useState(false)
  
  const handleOpenEditHotelModal=(data,index)=>{
    setOpenEditHotelModal(true)
    setHotelData(data)
    setHotelIndex(index)
  }

  const handleCloseEditHotelModal=()=>{
    setOpenEditHotelModal(false)
  }


  //View Activities
  const [openViewActivitiesModal,setOpenViewActivitiesModal] = useState(false)
  const [activitiesData,setActivitiesData] = useState(false)
  
  const handleOpenViewActivitiesModal=(data)=>{
    setOpenViewActivitiesModal(true)
    setActivitiesData(data)
  }
  
  const handleCloseViewActivitiesModal=()=>{
    setOpenViewActivitiesModal(false)
  }


  //View Mail Opening
  const [openViewMailOpensModal,setOpenViewMailOpensModal] = useState(false)
  const [mailOpensData,setMailOpensData] = useState(false)
  
  const handleOpenViewMailOpensModal=(data)=>{
    setOpenViewMailOpensModal(true)
    setMailOpensData(data)
  }
  
  const handleCloseViewMailOpensModal=()=>{
    setOpenViewMailOpensModal(false)
  }



  //Items Table
  const [itemsTableRows , setItemsTableRows] = useState([])

  const [editableItemData, setEditableItemData] = useState(null)

  const [itemIndex, setItemIndex] = useState(0)

  const [totalNights, setTotalNights] = useState(0)

  const handleAddNewItem = (data) => {
    setItemsTableRows((prevItems) => [...prevItems, data]);
    setTotalNights((prevTotalNights) => prevTotalNights + parseInt(data.nights, 10));
  };

  const handleDuplicate =(i)=>{
    setItemsTableRows((prevItems)=>[...prevItems.slice(0,i+1),prevItems[i],...prevItems.slice(i+1)])
    setTotalNights(+totalNights + +itemsTableRows
      [i].nights);
  }

  const handleEditItem=(data)=>{
    setTotalNights(+totalNights + +data.nights - +itemsTableRows[itemIndex].nights)
    const updatedItemsTableRows = itemsTableRows.map((item, index) => {
      if (index === itemIndex) {
        return data;
      } else {
        return item;
      }
    });
    setItemsTableRows(updatedItemsTableRows)
  }

  const handleDeleteItem=(index)=>{
   const newItems = itemsTableRows.filter((item, i) => i !== index);
   const deletedItemNights = +itemsTableRows[index].nights;
   const newTotalNights = +totalNights - deletedItemNights;
   setItemsTableRows(newItems);
   setTotalNights(newTotalNights);
  }
  
  const [openAddItemModal,setOpenAddItemModal] = useState(false)
  
  const handleOpenAddItemModal=()=>{
    setOpenAddItemModal(true)
  }
  
  const handleCloseAddItemModal=()=>{
    setOpenAddItemModal(false)
  }

  const [openEditItemModal,setOpenEditItemModal] = useState(false)
  
  const handleOpenEditItemModal=(data,index)=>{
    setOpenEditItemModal(true)
    setEditableItemData(data)
    setItemIndex(index)
  }
  
  const handleCloseEditItemModal=()=>{
    setOpenEditItemModal(false)
  }


  //Delete Invoices
  const [openDeleteInvoiceModal , setOpenDeleteInvoiceModal] = useState(false)

  const handleCloseDeleteInvoiceModal = ()=>{
    setOpenDeleteInvoiceModal(false)
  }

  const handleOpenDeleteInvoiceModal = ()=>{
    setOpenDeleteInvoiceModal(true)
  }

  //Search for PDFs
  const [searchForInvoicesQuery,setSearchForInvoicesQuery] = useState("")

  //Reset Data of Items Table
  const handleResetItemsTable=()=>{
    setTotalNights(0)
    setItemsTableRows([])
  }

  //Invoices Routes
  const invocieRoutes = [process.env.REACT_APP_INVOICES_ROUTE, `${process.env.REACT_APP_INVOICE_ROUTE}/${id}`,`${process.env.REACT_APP_INVOICE_CLIENT_ROUTE}/${id}`,  process.env.REACT_APP_CREATE_INVOICE_ROUTE, `${process.env.REACT_APP_EDIT_INVOICE_ROUTE}/${id}`]
  

  const values={handleResetItemsTable,invocieRoutes,handleCloseChooseNumberOfDuplicateInvoices,handleOpenChooseNumberOfDuplicateInvoices,OpenChooseNumberOfDuplicateInvoices,setTotalNights,setItemsTableRows,searchForInvoicesQuery,setSearchForInvoicesQuery,handleCloseDeleteInvoiceModal,handleOpenDeleteInvoiceModal,openDeleteInvoiceModal,handleDuplicate,totalNights,handleEditItem,openEditItemModal,handleOpenEditItemModal,handleCloseEditItemModal,editableItemData,setEditableItemData,handleDeleteItem,handleAddNewItem,openAddItemModal,handleOpenAddItemModal,handleCloseAddItemModal,itemsTableRows,openViewMailOpensModal,mailOpensData,handleOpenViewMailOpensModal,handleCloseViewMailOpensModal,handleOpenViewActivitiesModal,openViewActivitiesModal,handleCloseViewActivitiesModal,activitiesData,openChooseNumberOfHotelModal,handleOpenChooseNumberOfHotelModal,handleCloseChooseNumberOfHotelModal,handleCloseDeleteLogModal,handleOpenDeleteLogModal,openDeleteLogModal,logId,openChooseHotelDialog,handleCloseChooseHotelDialog,handleOpenChooseHotelDialog,hotelIndex,logData,setLogData,setSearchForLogsQuery,searchForLogsQuery,openDownloadLogModal,handleOpenDownloadLogModal,handleCloseDownloadLogModal,openSendMailModal,handleCloseSendMailModal,handleOpenSendMailModal,openEditHotelModal,hotelData,handleOpenEditHotelModal,handleCloseEditHotelModal,setHotelIndex}
  
  return (
    <AppContext.Provider value={values}>{children}</AppContext.Provider>
  )
}

export default AppProvider