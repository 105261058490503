import { FacebookRounded, Instagram, LinkedIn } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryContainer } from "mui/PrimaryContainer"
import { Link } from "react-router-dom"
import styles from "./Footer.module.scss"

export const Footer = () => {
  return (
    <PrimaryBox component={"footer"}>
      <PrimaryContainer className={`grid jcs aic g50 ${styles.footer_contain}`}>
        <Box className={`grid jcs aifs g40 ${styles.top_footer}`}>
          <Box className={`grid jcs aic g30`}>
            <Box className={`grid jcs aic g10`}>
              <Typography variant="h5" className="fw700" >Address</Typography>
              <Typography variant="h6" >13 Newel St. Apt# 1RR,  Brooklyn, NY 11222</Typography>
            </Box>
            <Box className={`grid jcs aic g10`}>
              <Typography variant="h5" className="fw700" >Phone</Typography>
              <Typography variant="h6" >(332) 2626-BTB</Typography>
            </Box>
          </Box>
          <Box className={`grid jcs aic g20`}>
            <Typography variant="h5" className="fw700" >Email</Typography>
            <Box className={`grid jcs aic g5`}>
              <Typography variant="h6" className="fw700">Support Team</Typography>
              <Typography variant="h6" className="fw500">support@btbintl.com</Typography>
            </Box>
            <Box className={`grid jcs aic g5`}>
              <Typography variant="h6" className="fw700">Reservations Team</Typography>
              <Typography variant="h6" className="fw500">res@btbintl.com</Typography>
            </Box>
          </Box>
          <Box className={`grid jcs aic g20`}>
            <Typography variant="h5" className="fw700" >Let's Get Social</Typography>
            <Box className={`${styles.social} flex jcs aic g30`}>
              <IconButton onClick={() => window.location.href = "https://www.linkedin.com/company/business-travel-bureau"}>
                <LinkedIn />
              </IconButton>
              <IconButton onClick={() => window.location.href = "https://www.facebook.com/businesstravelbureau?_rdc=1&_rdr"}>
                <FacebookRounded />
              </IconButton>
              <IconButton onClick={() => window.location.href = "https://www.instagram.com/btbintl/"}>
                <Instagram />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Link to={"https://www.btbintl.com/success"}>
          <Typography className={`${styles.link}`} variant="subtitle2">© 2023 by BTBINTL.</Typography>
        </Link>
      </PrimaryContainer>
    </PrimaryBox>
  )
}
