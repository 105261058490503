import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'

export const getHotel = createAsyncThunk("chosenHotels/getHotel",async(args)=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_COOKIE_NAME}`)
  let returnedData;
  if(args.hasOwnProperty("link")){
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/scrape?site=${args.link}`,{
      headers:{
        Authorization:`Bearer ${token}`
      }
    })
    returnedData =  {data : res.data.data , index:args.index , link:args.link}
  }else {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/displayHotel/${args.id}`,{
      headers:{
        Authorization:`Bearer ${token}`
      }
    })
    returnedData =  {data : res.data.data , index:args.index}
  }
  return returnedData
}) 

const initialState = {
  chosenHotels: [],
  numberOfHotels: 0,
  isLoading:true,
  loading:false
}

export const chosenHotelsSlice = createSlice({
  name: 'chosenHotels',
  initialState,
  reducers: {
    handleHotelsArray:(state,action)=>{
      const {numberOfHotels} = action.payload
      state.numberOfHotels = +numberOfHotels
      localStorage.setItem("numberOfHotels" , JSON.stringify(numberOfHotels))
      let hotels = []
      new Array(+numberOfHotels).fill(0).map(()=>{
        hotels.push({data:null,price:null,currency:null,link:null})
        return 0;
      })
      state.chosenHotels = hotels
    },
    handleEditableHotels:(state,{payload})=>{
      let hotels = []
      payload.hotels.map((hotel)=>{
        hotels.push({data:hotel.Id,price:hotel.Price,currency:hotel.Currency,link:null})
        return 0;
      })
      state.chosenHotels = hotels
      state.numberOfHotels = +payload.hotels.length
    },
    setHotelPrice:(state,action)=>{
      const {index, price,currency} = action.payload
      state.chosenHotels[index].price = price
      state.chosenHotels[index].currency = currency
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getHotel.pending, (state) => {
      state.isLoading = true
      state.loading = true
    })
    builder.addCase(getHotel.fulfilled, (state, { payload }) => {
      state.chosenHotels[+payload.index].data = payload.data
      state.chosenHotels[+payload.index].link = payload.link
      state.loading = false
      state.isLoading = false
    })
    builder.addCase(getHotel.rejected, (state, action) => {
      state.loading = false
      state.isLoading = true
    })
  },
})

export const {handleHotelsArray,handleEditableHotels,setHotelPrice} = chosenHotelsSlice.actions
export default chosenHotelsSlice.reducer