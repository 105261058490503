import { Box, Modal, Typography } from "@mui/material"
import { AppContext } from "context/AppContext"
import { handleDate } from "functions/handleDate"
import { handleTiming } from "functions/handleTiming"
import { useContext } from "react"
import { useLocation, useParams } from "react-router-dom"
import "./modal.scss"

const LogActivitiesModal = () => {
  const { openViewActivitiesModal, handleCloseViewActivitiesModal, activitiesData } = useContext(AppContext)
  const { pathname } = useLocation()
  const { id } = useParams()

  return (
    <Modal
      open={openViewActivitiesModal}
      onClose={handleCloseViewActivitiesModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal br10 pad20 grid jcs aic center_abs_x_y grid jcs aic g30">
        <Typography variant="h4" className="tac fw700" sx={{ color: (theme) => theme.palette.primary.main }}>{pathname === `${process.env.REACT_APP_LOG_ROUTE}/${id}` ? "Log Activities" : "Invoice Activities"}</Typography>
        {activitiesData && activitiesData.length > 0 ? activitiesData.map((activity, i) => (
          <Box className={`pad10 br6 activity grid jcs aic g10`} key={i}>
            <Box className={`flex jcsb aic g20 flex_wrap`}>
              <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.primary.main }}>{activity.action}</Typography>
              <Typography variant="h6" className="fw600">{activity.email}</Typography>
            </Box>
            <Box className={`flex jcc aic g20`}>
              <Typography variant="h6">{handleDate(activity.date)}</Typography>
              <Typography variant="h6">{handleTiming(activity.date)}</Typography>
            </Box>
          </Box>
        )) : (<Typography variant="h4" className="tac" sx={{ color: "#eee" }} >No Activities Yet...</Typography>)}
      </Box>
    </Modal>
  )
}

export default LogActivitiesModal