import { Box, Divider, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getHotel, setHotelPrice } from "store/chosenHotelsSlice"
import HotelPDF from "../../components/HotelPDF/HotelPDF"
import LoadButton from "../../components/LoadButton/LoadButton"
import { AppContext } from "../../context/AppContext"
import { PrimaryButton } from "../../mui/PrimaryButton"
import { PrimaryTextField } from "../../mui/PrimaryTextField"
import styles from "./EditLog.module.scss"

const Hotel = ({ index }) => {
  const { handleOpenChooseHotelDialog, handleOpenEditHotelModal } = useContext(AppContext)
  const [link, setLink] = useState()
  const { chosenHotels, loading } = useSelector((state) => state.chosenHotels)
  const dispatch = useDispatch()
  const [price, setPrice] = useState(() => chosenHotels[index].price)
  const [currency, setCurrency] = useState(() => chosenHotels[index].currency)

  const handleChangePrice = (e) => {
    dispatch(setHotelPrice({ price: e.target.value, index }))
    setPrice(e.target.value)
  }

  const handleChangeCurrency = (e) => {
    dispatch(setHotelPrice({ price, index, currency: e.target.value }))
    setCurrency(e.target.value)
  }

  return (
    <Box className={`grid jcs aifs g30 ${styles.hotel}`}>
      <Box className={`grid jcs aic g30`}>
        <Box className={`grid jcs aic g10`}>
          <Box className={`grid jcs aic g10`}>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="text"
              id="hotel"
              name="hotel"
              label={"Choose a Hotel"}
              value={chosenHotels[index].data ? chosenHotels[index].data.name : ""}
              onClick={() => handleOpenChooseHotelDialog(index)}
            />
            {
              chosenHotels[index].data && <PrimaryButton onClick={() => handleOpenEditHotelModal(chosenHotels[index].data, index)}>Edit Hotel</PrimaryButton>
            }
          </Box>
          <Box className={`flex jcc aic relative`}>
            <Divider sx={{ width: "100%", zIndex: 0, borderColor: (theme) => theme.palette.primary.main }} className={`absolute`} />
            <Typography variant="subtitle1" sx={{ backgroundColor: (theme) => theme.palette.white, zIndex: 1 }} className={`pad5`} >or</Typography>
          </Box>
          <Box className={`grid jcs aic g10`}>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="text"
              id="hotel"
              name="hotel"
              label={"Find a Hotel By Link"}
              value={link ? link : ""}
              onChange={(e) => setLink(e.target.value)}
            />
            <LoadButton loading={loading}>
              <PrimaryButton onClick={() => dispatch(getHotel({ link, index }))}>Find</PrimaryButton>
            </LoadButton>
          </Box>
        </Box>
        <PrimaryTextField
          fullWidth
          variant="outlined"
          type="text"
          id="price"
          name="price"
          label={"Hotel Price"}
          value={price ? price : ""}
          onChange={handleChangePrice}
        />
        <PrimaryTextField
          fullWidth
          variant={"outlined"}
          id={"currency"}
          name={"currency"}
          value={currency ? currency : ""}
          onChange={handleChangeCurrency}
          select
          SelectProps={{
            native: true,
          }}
        >
          <option value={""}>
            Choose Currency
          </option>
          <option value={"£"} className="flex jcfs aic g10">
            Pound (£)
          </option>
          <option value={"€"} className="flex jcfs aic g10">
            euro (€)
          </option>
          <option value={"$"} className="flex jcfs aic g10">
            usd ($)
          </option>
          <option value={"CA$"} className="flex jcfs aic g10">
            canadian usd (CA$)
          </option>
        </PrimaryTextField>
      </Box>
      <Box className={`grid jcs aifs g20 ${styles.pdf_view}`}>
        <Typography variant="h5" className={`tac fw600`} >PDF View</Typography>
        <HotelPDF price={price} currency={currency} hotel={chosenHotels[index].data} />
      </Box>
    </Box>
  )
}

export default Hotel