import { Skeleton } from "@mui/material"
import styles from "./Invoice.module.scss"

const LoadingInvoice = () => {

  return (
    <Skeleton className={`br10 ${styles.loading_invoice}`} variant="rounded" />
  )
}

export default LoadingInvoice