import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'

export const getHotel = createAsyncThunk("hotel/getHotel",async(args)=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_COOKIE_NAME}`)
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/displayHotel/${args.id}`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return res.data.data
}) 

const initialState = {
  hotel: null,
  isLoading:true
}

export const hotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(getHotel.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getHotel.fulfilled, (state, { payload }) => {
      state.hotel = payload
      state.isLoading = false
    })
    builder.addCase(getHotel.rejected, (state, action) => {
      state.isLoading = true
    })
  },
})


export default hotelSlice.reducer