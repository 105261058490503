import { CloseRounded } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"

const ChipGhoul = ({ val, clicked }) => {
  return (
    <Box className={`flex jcc aic pad5 br6 g5`} sx={{ backgroundColor: (theme) => theme.palette.primary.main_alt, color: "#fff" }}>
      <Typography variant="h6" >{val}</Typography>
      <IconButton onClick={clicked}>
        <CloseRounded sx={{ color: (theme) => theme.palette.youtube }} />
      </IconButton>
    </Box>
  )
}

export default ChipGhoul