import { SearchRounded } from "@mui/icons-material"
import { Typography, useMediaQuery } from "@mui/material"
import { AppContext } from "context/AppContext"
import { useContext, useEffect } from "react"
import LoadButton from "../../components/LoadButton/LoadButton"
import { PrimaryButton } from "../../mui/PrimaryButton"
import { PrimaryContainer } from "../../mui/PrimaryContainer"
import { PrimaryTextField } from "../../mui/PrimaryTextField"

const SearchForLogsForm = ({ loading, formik }) => {
  const smSize = useMediaQuery("(max-width:768px)")
  const { setSearchForLogsQuery } = useContext(AppContext)

  useEffect(() => {
    setSearchForLogsQuery(formik.values.search)
  }, [formik])

  return (
    <PrimaryContainer className={`flex jcsb aic g30 `}>
      <PrimaryTextField
        fullWidth
        variant="outlined"
        type="text"
        id="search"
        name="search"
        label={"Search..."}
        value={formik.values.search}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.search && Boolean(formik.errors.search)}
        helperText={formik.touched.search && formik.errors.search}
      />
      <LoadButton loading={loading}>
        <PrimaryButton type={"submit"} className={`flex jcc aic g5`}>
          <SearchRounded />
          {!smSize && (<Typography sx={{ textWrap: "nowrap" }} variant="h6">Search</Typography>)}
        </PrimaryButton>
      </LoadButton>
    </PrimaryContainer>
  )
}

export default SearchForLogsForm