import { Box, Skeleton } from '@mui/material';
import styles from "./LogButtons.module.scss";

const LoadingLogButton = () => {
  return (
    <>
      <Box className={`grid jcs aic g30 ${styles.log_buttons}`}>
        <Skeleton variant='rounded' className={`${styles.load_button}`} />
        <Skeleton variant='rounded' className={`${styles.load_button}`} />
        <Skeleton variant='rounded' className={`${styles.load_button}`} />
      </Box>
      <Box className={`flex jcsb aic g30`}>
        <Skeleton variant='rounded' className={`${styles.load_button}`} />
        <Skeleton variant='rounded' className={`${styles.load_button}`} />
      </Box>
    </>
  )
}

export default LoadingLogButton