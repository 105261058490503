import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./authSlice"
import chosenHotelsReducer from "./chosenHotelsSlice"
import hotelReducer from "./hotelSlice"
import hotelsReducer from "./hotelsSlice"
import invoiceReducer from "./invoiceSlice"
import invoicesReducer from "./invoicesSlice"
import logReducer from "./logSlice"
import logsReducer from "./logsSlice"
import invoiceClientSlice from './invoiceClientSlice'

export const store = configureStore({
  reducer: {
    "auth":authReducer,
    "log":logReducer,
    "logs":logsReducer,
    "hotels":hotelsReducer,
    "chosenHotels":chosenHotelsReducer,
    "hotel":hotelReducer,
    "invoices":invoicesReducer,
    "invoice":invoiceReducer,
    "invoiceClient":invoiceClientSlice,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false, 
  }),
})