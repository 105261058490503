import { SpecialBox } from "mui/SpecialBox"
import ErrorSection from "sections/ErrorSection/ErrorSection"

const Error = () => {
  return (
    <SpecialBox>
      <ErrorSection />
    </SpecialBox>
  )
}

export default Error