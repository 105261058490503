import { Box, Skeleton } from '@mui/material';
import styles from "./LogInfo.module.scss";

const LoadingLogInfo = () => {
  return (
    <Box className={`grid jcs aic g20`}>
      <Skeleton variant="text" className={`${styles.load_log_info_title}`} />
      <Box className={`${styles.infos} grid jcs aic g30 pad20 br10`}>
        {
          new Array(8).fill(0).map((_,i)=>(
            <Box key={i} className={`flex_wrap flex jcfs aic g5 ${styles.info}`}>
              <Skeleton variant="text" className={`${styles.info_title}`} />
              <Skeleton variant="text" className={`${styles.info_value}`} />
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

export default LoadingLogInfo