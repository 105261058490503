import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryButton } from "mui/PrimaryButton"
import { PrimaryContainer } from "mui/PrimaryContainer"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useNavigate } from "react-router-dom"
import errorImg from "../../assets/images/error.jpg"
import styles from "./ErrorSection.module.scss"

const ErrorSection = () => {
  const navigate = useNavigate()
  return (
    <PrimaryBox>
      <PrimaryContainer className={`${styles.error_section} grid jcs aic g50`}>
        <LazyLoadImage src={errorImg} alt={"Erorr"} />
        <PrimaryButton sx={{ width: "fit-content" }} className="center_rel_x" onClick={() => navigate(`${process.env.REACT_APP_HOME_ROUTE}`)}>Go Home</PrimaryButton>
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default ErrorSection