import { SpecialBox } from 'mui/SpecialBox'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { HotelCoverSection } from 'sections/HotelCoverSection/HotelCoverSection'
import HotelDescriptionSection from 'sections/HotelDescriptionSection/HotelDescriptionSection'
import HotelListsSection from 'sections/HotelListsSection/HotelListsSection'
import { getHotel } from 'store/hotelSlice'

const Hotel = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { hotel, isLoading } = useSelector((state) => state.hotel)
  useEffect(() => {
    dispatch(getHotel({ id }))
  }, [dispatch, id])
  return (
    <SpecialBox className={`grid jcs aic g30`}>
      {isLoading ? (<></>) : hotel && (
        <>
          <HotelCoverSection hotel={hotel} />
          <HotelDescriptionSection hotel={hotel} />
          <HotelListsSection hotel={hotel} />
        </>
      )}
    </SpecialBox>
  )
}

export default Hotel