import { Typography } from "@mui/material"
import HotelPhotos from "components/HotelPhotos/HotelPhotos"
import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryContainer } from "mui/PrimaryContainer"
import styles from "./HotelDescriptionSection.module.scss"

const HotelDescriptionSection = ({ hotel }) => {
  return (
    <PrimaryBox>
      <PrimaryContainer className={`grid jcs aifs g30 ${styles.hotel_description_section}`}>
        <HotelPhotos photos={hotel.photos} />
        <Typography variant="h6" className={`tas`}>{hotel.description}</Typography>
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default HotelDescriptionSection