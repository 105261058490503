import { PrimaryBox } from "mui/PrimaryBox"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getLogs } from "store/logsSlice"
import Forms from "../forms/Forms"
import { SpecialBox } from "../mui/SpecialBox"
import LogsSection from "../sections/LogsSection/LogsSection"

const Home = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLogs({ count: 0, search: "" }))
  }, [dispatch])

  return (
    <SpecialBox>
      <PrimaryBox className="grid jcs aic g30">
        <Forms type={"search_for_logs"} />
        <LogsSection />
      </PrimaryBox>
    </SpecialBox>
  )
}

export default Home