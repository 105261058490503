import Forms from "forms/Forms"
import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryContainer } from "mui/PrimaryContainer"
import { SpecialBox } from "mui/SpecialBox"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { getInvoice } from "store/invoiceSlice"

const EditInvoice = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(getInvoice({ id }))
  }, [dispatch, id])
  return (
    <SpecialBox>
      <PrimaryBox>
        <PrimaryContainer>
          <Forms type={"edit_invoice"} />
        </PrimaryContainer>
      </PrimaryBox>
    </SpecialBox>
  )
}

export default EditInvoice