import { MailRounded } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import LoadButton from "components/LoadButton/LoadButton"
import { AppContext } from "context/AppContext"
import { DeleteButton } from "mui/DeleteButton"
import { PrimaryButton } from "mui/PrimaryButton"
import { useContext } from "react"
const SendMailForm = ({loading }) => {
  const {handleCloseSendMailModal} = useContext(AppContext)

  return (
    <Box className="grid jcs aic g50">
      <Box className={`flex jcc aic g10`}>
        <MailRounded sx={{color:(theme)=>theme.palette.yellow}} />
        <Typography variant="h4" className={`tac`}>Send Mail</Typography>
      </Box>
      <Box className={`flex jcsb aic g30`}>
        <DeleteButton onClick={handleCloseSendMailModal}>Cancel</DeleteButton>
        <LoadButton loading={loading}>
          <PrimaryButton type={"submit"} className={`flex jcc aic g5`}>
            <Typography variant="h6">Send</Typography>
          </PrimaryButton>
        </LoadButton>
      </Box>
  </Box>
  )
}

export default SendMailForm