import { Box, Modal } from "@mui/material"
import { AppContext } from "context/AppContext"
import Forms from "forms/Forms"
import { useContext } from "react"
import "./modal.scss"

const EditHotelModal = () => {
  const { openEditHotelModal, handleCloseEditHotelModal } = useContext(AppContext)

  return (
    <Modal
      open={openEditHotelModal}
      onClose={handleCloseEditHotelModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal br10 pad20 grid jcs aic center_abs_x_y">
        <Forms type={"edit_hotel"} />
      </Box>
    </Modal>
  )
}

export default EditHotelModal