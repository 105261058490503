import { Box, Skeleton } from "@mui/material"
import styles from "./LogHotels.module.scss"

const LoadingLogHotels = () => {
  return (
    <Box className={`grid cjs aic g20 ${styles.log_hotels}`}>
      <Skeleton variant="text" className={`${styles.load_log_hotel_title}`} />
      <Box className={`grid jcs aifs g20 ${styles.log_hotels_accordions}`}>
        {
          new Array(Math.floor(Math.random()*4)+1).fill(0).map((_,i)=>(
            <Skeleton key={i} className={`br6 ${styles.load_accordion}`}/>
          ))
        }
      </Box>
    </Box>
  )
}

export default LoadingLogHotels