import { Box, ThemeProvider } from "@mui/material";
import { Footer } from "components/Footer/Footer";
import AppProvider from "context/AppContext";
import ChooseHotelDialog from "dialogs/ChooseHotelDialog";
import { handleAlert } from "functions/handleAlert";
import Cookies from "js-cookie";
import AddItemModal from "modals/AddItemModal";
import ChooseNumberOfHotelModal from "modals/ChooseNumberOfHotelModal";
import DeleteLogModal from "modals/DeleteLogModal";
import DownloadLogModal from "modals/DownloadLogModal";
import EditHotelModal from "modals/EditHotelModal";
import EditItemModal from "modals/EditItemModal";
import LogActivitiesModal from "modals/LogActivitiesModal";
import LogMailOpensModal from "modals/LogMailOpensModal";
import SendMailModal from "modals/SendMailModal";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { setAuth } from "store/authSlice";
import Header from "./components/Header/Header";
import { theme } from "./theme";
import ChooseNumberOfDuplicates from "modals/ChooseNumberOfDuplicateInvoices";

function App() {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const {id} = useParams()
  const {isSigned} = useSelector((state)=>state.auth)
  const dispatch = useDispatch()

  useEffect(()=>{
    //Handle Token
    let token = null
    let userName = null
    let invoiceToken = null
    let invoiceUserName = null
    
    const endpointMap = new Set([
      "/invoicesLogin",
      "/invoices",
      "/invoice",
      "/create-invoice",
      "/edit-invoice"
    ]);
    try{
      token = Cookies.get(`${process.env.REACT_APP_TOKEN_COOKIE_NAME}`)
      userName = Cookies.get(`${process.env.REACT_APP_USERNAME_COOKIE_NAME}`)
      invoiceToken = Cookies.get(`invoices${process.env.REACT_APP_TOKEN_COOKIE_NAME}`)
      invoiceUserName = Cookies.get(`invoices${process.env.REACT_APP_USERNAME_COOKIE_NAME}`)
      // console.log("pathname");
      // console.log( pathname.startsWith(`/invoice`));
      if(invoiceToken && invoiceUserName && ( endpointMap.has(pathname) || pathname.startsWith(`/invoice`) || pathname.startsWith(`/edit-invoice`))){
        dispatch(setAuth({invoiceToken,invoiceUserName}))
      }else if(token && userName && (!endpointMap.has(pathname) && !pathname.startsWith(`${process.env.REACT_APP_INVOICE_ROUTE}`))){
        dispatch(setAuth({token,userName}))
      }else if(pathname === `${process.env.REACT_APP_INVOICE_CLIENT_ROUTE}/${id}`){ 
      }else{
        if(pathname.startsWith(`${process.env.REACT_APP_INVOICE_ROUTE}`) || endpointMap.has(pathname) )
        {
          // console.log("here");
          navigate(`${process.env.REACT_APP_INVOICES_LOGIN_ROUTE}`)
        }else if(pathname !== `${process.env.REACT_APP_LOGIN_ROUTE}` && !pathname.startsWith(`${process.env.REACT_APP_HOTEL_ROUTE}/`)) {
          
          // console.log(`${process.env.REACT_APP_HOTEL_ROUTE}/${id}`);
          // console.log(!pathname.startsWith(`${process.env.REACT_APP_HOTEL_ROUTE}/`));
          // console.log(pathname !== `${process.env.REACT_APP_LOGIN_ROUTE}`);

          navigate(`${process.env.REACT_APP_LOGIN_ROUTE}`)
        }
      }
    }catch(err){
      handleAlert(err.message,"error")
    }

    //Handle LocalStorage
    if(!localStorage.getItem("numberOfHotels") && (pathname === `${process.env.REACT_APP_ADD_LOG_ROUTE}` || pathname === `${process.env.REACT_APP_EDIT_LOG_ROUTE}`)){
      navigate(`${process.env.REACT_APP_HOME_ROUTE}`)
      handleAlert("Choose Number of Hotels","error")
    }
  },[pathname,navigate,isSigned,dispatch])

  return (
    <ThemeProvider theme= {theme}>
      <AppProvider>
        <Box component={"main"} className="grid jcs aifs">
          <Header/>
          <Outlet />
          {pathname === `${process.env.REACT_APP_HOTEL_ROUTE}/${id}` && <Footer/>}
          {/* Modals */}
          <ChooseNumberOfHotelModal/>
          <ChooseNumberOfDuplicates/>
          <DeleteLogModal/>
          <DownloadLogModal/>
          <SendMailModal/>
          <EditHotelModal/>
          <LogActivitiesModal/>
          <LogMailOpensModal/>
          <AddItemModal/>
          <EditItemModal/>
          {/* Modals */}
          
          {/* Dialog */}
          <ChooseHotelDialog/>
          {/* Dialog */}
          <Toaster/>
        </Box>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
