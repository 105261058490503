import { EditRounded, RemoveRedEyeRounded } from "@mui/icons-material"
import { Box, Paper, Typography } from "@mui/material"
import { handleDate } from "functions/handleDate"
import { handleTiming } from "functions/handleTiming"
import { PrimaryButton } from "mui/PrimaryButton"
import { SecondaryButton } from "mui/SecondaryButton"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import styles from "./Log.module.scss"

const Log = ({ log, index }) => {
  const { Agent, CustomerEmail, CustomerName, createdAt } = log
  const navigate = useNavigate()
  const { count } = useSelector((state) => state.logs)

  return (
    <Paper className={`grid jcs aic pad20 g30 br10 ${styles.log}`}>
      <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} ># {index + 1 + count * 10}</Typography>
      <Box className={`grid jcs aic g20`}>
        <Box className={`flex jcfs aic g10 flex_wrap`}>
          <Typography variant="h6" className="fw700">Agent Name : </Typography>
          <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} className="main fw700">{Agent}</Typography>
        </Box>
        <Box className={`flex jcfs aic g10 flex_wrap`}>
          <Typography variant="h6" className="fw700">Customer Name : </Typography>
          <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} className="main fw700">{CustomerName}</Typography>
        </Box>
        <Box className={`flex jcfs aic g10 flex_wrap`}>
          <Typography variant="h6" className="fw700">Customer Email : </Typography>
          <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} className="main fw700">{CustomerEmail}</Typography>
        </Box>
      </Box>
      <Box className={`flex jcc aic g20`}>
        <Typography className="tac fw600" variant="h6" >{handleDate(createdAt)}</Typography>
        <Typography className="tac fw600" variant="h6" >{handleTiming(createdAt)}</Typography>
      </Box>
      <Box className={`flex jcsb aic g20`}>
        <Link to={`${process.env.REACT_APP_EDIT_LOG_ROUTE}/${log._id}`}>
          <SecondaryButton fullWidth className={`flex jcc aic g10`}>
            <EditRounded sx={{ color: (theme) => theme.palette.primary.main }} />
            <Typography variant="h6" className="fw700">Edit</Typography>
          </SecondaryButton>
        </Link>


        <Link to={`${process.env.REACT_APP_LOG_ROUTE}/${log._id}`}>
          <PrimaryButton fullWidth className={`flex jcc aic g10`}>
            <RemoveRedEyeRounded sx={{ color: (theme) => theme.palette.white }} />
            <Typography variant="h6" className="fw700">View</Typography>
          </PrimaryButton>
        </Link>
      </Box>
    </Paper>
  )
}

export default Log