import { AppContext } from "context/AppContext"
import Forms from "forms/Forms"
import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryContainer } from "mui/PrimaryContainer"
import { SpecialBox } from "mui/SpecialBox"
import { useContext, useEffect } from "react"

const CreateInvoice = () => {
  const { handleResetItemsTable } = useContext(AppContext)
  useEffect(() => {
    handleResetItemsTable()
  }, [])
  return (
    <SpecialBox>
      <PrimaryBox>
        <PrimaryContainer>
          <Forms type={"create_invoice"} />
        </PrimaryContainer>
      </PrimaryBox>
    </SpecialBox>
  )
}

export default CreateInvoice