import { Box, Typography } from "@mui/material"
import LoadingLog from "components/Log/LoadingLog"
import Paginate from "components/Paginate/Paginate"
import { useSelector } from "react-redux"
import Log from "../../components/Log/Log"
import { PrimaryContainer } from "../../mui/PrimaryContainer"
import styles from "./LogsSection.module.scss"

const LogsSection = () => {
  const { logs, totalLogs, isLoading } = useSelector((state) => state.logs)
  return (
    <PrimaryContainer className={`grid jcs aic g30`}>
      <Box className={`grid jcs aic g30 ${styles.logs_section}`}>
        {
          isLoading ? (new Array(Math.floor(Math.random() * 6) + 5).fill(0).map((_, i) => (
            <LoadingLog key={i} />
          ))) : logs && logs.length > 0 ?
            logs.map((log, i) => (
              <Log log={log} key={i} index={i} />
            ))
            : (<Typography variant="h4" className={`tac`} sx={{ color: (theme) => theme.palette.gray }}>No Logs Yet...</Typography>)
        }
      </Box>
      {
        totalLogs > 10 && <Paginate count={Math.ceil(totalLogs / 10)} />
      }
    </PrimaryContainer>
  )
}

export default LogsSection