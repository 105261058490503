import { EditRounded } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import LoadButton from 'components/LoadButton/LoadButton'
import { PrimaryButton } from 'mui/PrimaryButton'
import { PrimaryTextField } from 'mui/PrimaryTextField'

const EditHotel = ({loading,formik}) => {
  return (
    <Box className={`grid jcs aic g50`}>
     <Box className={`flex jcc aic g10`}>
        <EditRounded sx={{color:(theme)=>theme.palette.primary.main}} />
        <Typography variant="h4" className={`tac`}>Edit Hotel</Typography>
      </Box>
      <Box className={`grid jcs aic g30`}>
        <PrimaryTextField
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          type="text"
          id="description"
          name="description"
          label={"Description"}
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
        <LoadButton loading={loading}>
          <PrimaryButton type={"submit"} className={`flex jcc aic g5`}>Edit</PrimaryButton>
        </LoadButton>
      </Box>
    </Box>
  )
}

export default EditHotel