import { DownloadRounded } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import LoadButton from "components/LoadButton/LoadButton"
import { AppContext } from "context/AppContext"
import { DeleteButton } from "mui/DeleteButton"
import { PrimaryButton } from "mui/PrimaryButton"
import { PrimaryTextField } from "mui/PrimaryTextField"
import { useContext } from "react"

const DownloadPDFForm = ({loading,formik }) => {
  const {handleCloseDownloadLogModal} = useContext(AppContext)

  return (
    <Box className="grid jcs aic g50">
      <Box className={`flex jcc aic g10`}>
        <DownloadRounded sx={{color:(theme)=>theme.palette.whatsapp}} />
        <Typography variant="h4" className={`tac`}>Download PDF</Typography>
      </Box>
      <PrimaryTextField
        fullWidth
        variant="outlined"
        type="text"
        id="fileName"
        name="fileName"
        label={"File Name"}
        value={formik.values.fileName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.fileName && Boolean(formik.errors.fileName)}
        helperText={formik.touched.fileName && formik.errors.fileName}
      />
      <Box className={`flex jcsb aic g30`}>
        <DeleteButton onClick={handleCloseDownloadLogModal}>Cancel</DeleteButton>
        <LoadButton loading={loading}>
          <PrimaryButton type={"submit"} className={`flex jcc aic g5`}>
            <Typography variant="h6">Download</Typography>
          </PrimaryButton>
        </LoadButton>
      </Box>
  </Box>
  )
}

export default DownloadPDFForm