import HotelList from "components/HotelList/HotelList"
import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryContainer } from "mui/PrimaryContainer"
import styles from "./HotelListsSection.module.scss"

const HotelListsSection = ({ hotel }) => {
  const listsTitle = ["Activities", "General", "Internet", "Parking", "Services"]
  return (
    <PrimaryBox>
      <PrimaryContainer className={`grid jcs aifs g30 ${styles.lists_sections}`}>
        {
          listsTitle.map((title, i) => (
            hotel[title].length > 0 && (
              <HotelList title={title} list={hotel[title]} key={i} />
            )
          ))
        }
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default HotelListsSection