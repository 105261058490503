import { Box, IconButton } from "@mui/material"
import { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import styles from "./HotelPhotos.module.scss"

const HotelPhotos = ({ photos }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <Box className={`grid jcs aic g20 ${styles.hotel_photos}`}>
      <Box className={`${styles.main_photo}`}>
        <LazyLoadImage src={photos[currentIndex]} alt={"main"} />
      </Box>
      <Box className={`grid jcs aifs g10 ${styles.photos}`}>
        {
          photos.map((photo, i) => (
            i > 0 && <IconButton onClick={() => setCurrentIndex(i)} key={i} className={`br4 ${styles.photos_button}`}>
              <LazyLoadImage src={photo} alt={"main"} />
            </IconButton>
          ))
        }
      </Box>
    </Box>
  )
}

export default HotelPhotos