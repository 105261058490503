import { Box, Modal, Typography } from "@mui/material"
import { AppContext } from "context/AppContext"
import { handleDate } from "functions/handleDate"
import { handleTiming } from "functions/handleTiming"
import { useContext } from "react"
import "./modal.scss"

const LogMailOpensModal = () => {
  const { openViewMailOpensModal, handleCloseViewMailOpensModal, mailOpensData } = useContext(AppContext)

  return (
    <Modal
      open={openViewMailOpensModal}
      onClose={handleCloseViewMailOpensModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal br10 pad20 grid jcs aic center_abs_x_y grid jcs aic g30">
        <Typography variant="h4" className="tac fw700" sx={{ color: (theme) => theme.palette.primary.main }}>Tracking</Typography>
        {mailOpensData && mailOpensData.length > 0 ? mailOpensData.map((mail, i) => (
          <Box className={`pad10 br6 activity grid jcs aic g10`} key={i}>
            <Typography variant="h6" className="fw600" sx={{ color: (theme) => theme.palette.primary.main }}>{mail.email}</Typography>
            <Box className={`flex jcc aic g20`}>
              <Typography variant="h6">{handleDate(mail.date)}</Typography>
              <Typography variant="h6">{handleTiming(mail.date)}</Typography>
            </Box>
          </Box>
        )) : (<Typography variant="h4" className="tac" sx={{ color: "#eee" }} >No Mails Open Yet...</Typography>)}
      </Box>
    </Modal>
  )
}

export default LogMailOpensModal