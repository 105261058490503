import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { PrimaryButton } from "mui/PrimaryButton";
import { SecondaryButton } from "mui/SecondaryButton";
import styles from "./SendEmailPopup.module.scss";

const SendEmailPopup = ({ open, handleClose, handleSendEmail, initialCcEmails = [""] }) => {
  const [subject, setSubject] = useState("");
  const [ccEmails, setCcEmails] = useState(initialCcEmails); // Initialize with passed email
  const [message, setMessage] = useState("");
  const [hotelOptionCode, setHotelOptionCode] = useState("");

  const handleCcChange = (index, value) => {
    const updatedCcEmails = [...ccEmails];
    updatedCcEmails[index] = value;
    setCcEmails(updatedCcEmails);
  };

  const addCcField = () => {
    setCcEmails([...ccEmails, ""]);
  };

  const handleSubmit = () => {
    const formData = {
      subject,
      ccEmails,
      message,
      hotelOptionCode,
    };
    handleSendEmail(formData);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={styles.modal}>
        <Typography variant="h6">Send Email</Typography>
        <TextField
          label="Subject"
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        {ccEmails.map((email, index) => (
          <Box key={index} className={styles.ccField}>
            <TextField
              label={`${index === 0 ? "To" : "CC"} Email ${index + 1}`}
              fullWidth
              value={email}
              onChange={(e) => handleCcChange(index, e.target.value)}
            />
          </Box>
        ))}
        <Button className={styles.ccField} onClick={addCcField}>
          + Add Another CC
        </Button>
        <Box className={styles.ccField}>
          <TextField
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Box>
        <Box className={styles.ccField}>
          <TextField
            label="Hotel Option Code"
            fullWidth
            value={hotelOptionCode}
            onChange={(e) => setHotelOptionCode(e.target.value)}
          />
        </Box>
        <Box className={styles.buttons}>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleSubmit}>Send Email</PrimaryButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default SendEmailPopup;
