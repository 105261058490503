import { Box, Modal } from "@mui/material"
import { AppContext } from "context/AppContext"
import Forms from "forms/Forms"
import { useContext } from "react"
import "./modal.scss"

const AddItemModal = () => {
  const { openAddItemModal, handleCloseAddItemModal } = useContext(AppContext)

  return (
    <Modal
      open={openAddItemModal}
      onClose={handleCloseAddItemModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal br10 pad20 items grid g30 jcs aic center_abs_x_y">
        <Forms type={"add_new_item"} />
      </Box>
    </Modal>
  )
}

export default AddItemModal