import { AddRounded } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import ChipGhoul from "components/ChipGhoul/ChipGhoul"
import ItemsTable from "components/ItemsTable/ItemsTable"
import LoadButton from "components/LoadButton/LoadButton"
import { AppContext } from "context/AppContext"
import { handleAlert } from "functions/handleAlert"
import { PrimaryButton } from "mui/PrimaryButton"
import { PrimaryTextField } from "mui/PrimaryTextField"
import { SecondaryButton } from "mui/SecondaryButton"
import { useContext, useState } from "react"
import validator from 'validator'
import styles from "./CreateInvoice.module.scss"

const CreateInvoice = ({ formik, loading }) => {
  const { handleOpenAddItemModal } = useContext(AppContext)
  const [phone, setPhone] = useState("")
  const [phones, setPhones] = useState([])
  const [email, setEmail] = useState("")
  const [emails, setEmails] = useState([])

  const handleAddPhone = () => {
    if (!phone) {
      handleAlert('Enter Phone Number', "error")
      return
    }
    formik.values.phone = [...formik.values.phone, phone]
    setPhones((prev) => [...prev, phone])
    setPhone("")
  }

  const handleRemovePhone = (index) => {
    const newPhones = formik.values.phone.filter((_, i) => i !== index)
    formik.values.phone = newPhones
    setPhones(newPhones)
  }

  const handleAddEmail = () => {
    if (!email || !validator.isEmail(email)) {
      handleAlert('Invalid Email', "error")
      return
    }
    formik.values.email = [...formik.values.email, email]
    setEmails((prev) => [...prev, email])
    setEmail("")
  }

  const handleRemoveEmail = (index) => {
    const newEmail = formik.values.email.filter((_, i) => i !== index)
    formik.values.email = newEmail
    setEmails(newEmail)
  }

  return (
    <Box className={`grid jcs aifs g30 ${styles.create_invoice}`}>
      <Box className={`flex jcc aic g10`}>
        <AddRounded sx={{ color: (theme) => theme.palette.facebook }} />
        <Typography variant="h4" className={`tac`}>Create Invoice</Typography>
      </Box>

      <Box className={`grid jcs aic g30 `}>
        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            id="invoiceNumber"
            name="invoiceNumber"
            label={"Invoice Number"}
            value={formik.values.invoiceNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.invoiceNumber && Boolean(formik.errors.invoiceNumber)}
            helperText={formik.touched.invoiceNumber && formik.errors.invoiceNumber}
          />

          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            id="invoiceTo"
            name="invoiceTo"
            label={"Invoice To"}
            value={formik.values.invoiceTo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.invoiceTo && Boolean(formik.errors.invoiceTo)}
            helperText={formik.touched.invoiceTo && formik.errors.invoiceTo}
          />
        </Box>

        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            id="customer"
            name="customer"
            label={"Customer"}
            value={formik.values.customer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.customer && Boolean(formik.errors.customer)}
            helperText={formik.touched.customer && formik.errors.customer}
          />

          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            id="company"
            name="company"
            label={"Company"}
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
        </Box>

        <PrimaryTextField
          fullWidth
          variant="outlined"
          type="text"
          id="address"
          name="address"
          label={"Address"}
          value={formik.values.address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
        />

        <Box className={`flex jcs aifs g20  ${styles.md_wrap}`}>
          <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Box className={`flex jcs aic g10`} >
              <PrimaryTextField
                fullWidth
                variant="outlined"
                type="tel"
                id="phone"
                name="phone"
                label={"Phone"}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <SecondaryButton onClick={handleAddPhone}>
                <AddRounded />
              </SecondaryButton>
            </Box>
            <Box className={`flex flex_wrap jcfs aic g10`}>
              {
                phones.map((ph, i) => (
                  <ChipGhoul key={i} val={ph} clicked={() => handleRemovePhone(i)} />
                ))
              }
            </Box>
          </Box>

          <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Box className={`flex jcs aic g10`} sx={{ width: "100%" }}>
              <PrimaryTextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label={"Email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <SecondaryButton onClick={handleAddEmail}>
                <AddRounded />
              </SecondaryButton>
            </Box>
            <Box className={`flex flex_wrap jcfs aic g10`}>
              {
                emails.map((em, i) => (
                  <ChipGhoul key={i} val={em} clicked={() => handleRemoveEmail(i)} />
                ))
              }
            </Box>
          </Box>
        </Box>

        <PrimaryTextField
          fullWidth
          variant="outlined"
          type="text"
          id="hotelName"
          name="hotelName"
          label={"Hotel Name"}
          value={formik.values.hotelName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.hotelName && Boolean(formik.errors.hotelName)}
          helperText={formik.touched.hotelName && formik.errors.hotelName}
        />

        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            id="hotelAddress"
            name="hotelAddress"
            label={"Hotel Address"}
            value={formik.values.hotelAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.hotelAddress && Boolean(formik.errors.hotelAddress)}
            helperText={formik.touched.hotelAddress && formik.errors.hotelAddress}
          />

          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="tel"
            id="hotelPhone"
            name="hotelPhone"
            label={"Hotel Phone"}
            value={formik.values.hotelPhone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.hotelPhone && Boolean(formik.errors.hotelPhone)}
            helperText={formik.touched.hotelPhone && formik.errors.hotelPhone}
          />
        </Box>

        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">Check In Date</Typography>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="date"
              id="checkInDate"
              name="checkInDate"
              value={formik.values.checkInDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.checkInDate && Boolean(formik.errors.checkInDate)}
              helperText={formik.touched.checkInDate && formik.errors.checkInDate}
            />
          </Box>
          <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">Check Out Date</Typography>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="date"
              id="checkOutDate"
              name="checkOutDate"
              value={formik.values.checkOutDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.checkOutDate && Boolean(formik.errors.checkOutDate)}
              helperText={formik.touched.checkOutDate && formik.errors.checkOutDate}
            />
          </Box>
        </Box>

        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">Modification Date</Typography>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="date"
              id="modificationDate"
              name="modificationDate"
              value={formik.values.modificationDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.modificationDate && Boolean(formik.errors.modificationDate)}
              helperText={formik.touched.modificationDate && formik.errors.modificationDate}
            />
          </Box>
          <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">Remaining Balance Due</Typography>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="date"
              id="remainingBalanceDue"
              name="remainingBalanceDue"
              value={formik.values.remainingBalanceDue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.remainingBalanceDue && Boolean(formik.errors.remainingBalanceDue)}
              helperText={formik.touched.remainingBalanceDue && formik.errors.remainingBalanceDue}
            />
          </Box>
        </Box>

        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="number"
            label={"Tax in Precent Per Night"}
            id="taxInPrecent"
            name="taxInPrecent"
            value={formik.values.taxInPrecent}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.taxInPrecent && Boolean(formik.errors.taxInPrecent)}
            helperText={formik.touched.taxInPrecent && formik.errors.taxInPrecent}
          />
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="number"
            label={"Total Rooms"}
            id="totalRooms"
            name="totalRooms"
            value={formik.values.totalRooms}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.totalRooms && Boolean(formik.errors.totalRooms)}
            helperText={formik.touched.totalRooms && formik.errors.totalRooms}
          />
        </Box>

        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="number"
            label={"Collected Amount"}
            id="collectedAmount"
            name="collectedAmount"
            value={formik.values.collectedAmount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.collectedAmount && Boolean(formik.errors.collectedAmount)}
            helperText={formik.touched.collectedAmount && formik.errors.collectedAmount}
          />
          <PrimaryTextField
            fullWidth
            variant={"outlined"}
            id={"currency"}
            name={"currency"}
            value={formik.values.currency}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.currency && Boolean(formik.errors.currency)}
            helperText={formik.touched.currency && formik.errors.currency}
            select
            SelectProps={{
              native: true,
            }}
          >
            
            <option value={""}>
              Choose Currency
            </option>
            <option value={"£"} className="flex jcfs aic g10">
              Pound (£)
            </option>
            <option value={"€"} className="flex jcfs aic g10">
              euro (€)
            </option>
            <option value={"$"} className="flex jcfs aic g10">
              usd ($)
            </option>
            <option value={"CA$"} className="flex jcfs aic g10">
              canadian usd (CA$)
            </option>
          </PrimaryTextField>
          
        </Box>
        <Box className={`flex jcs aic g20  ${styles.md_wrap}`}>
        <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            label={"Attrition"}
            id="attrition"
            name="attrition"
            value={formik.values.attrition}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.attrition && Boolean(formik.errors.attrition)}
            helperText={formik.touched.attrition && formik.errors.attrition}
          />

          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            label={"Cancellation"}
            id="cancellation"
            name="cancellation"
            value={formik.values.cancellation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.cancellation && Boolean(formik.errors.cancellation)}
            helperText={formik.touched.cancellation && formik.errors.cancellation}
          />
        </Box>

        <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">Cut Off Date</Typography>
            <PrimaryTextField
              fullWidth
              variant="outlined"
              type="date"
              id="Cut Off Date"
              name="cutOffDate"
              value={formik.values.cutOffDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cutOffDate && Boolean(formik.errors.cutOffDate)}
              helperText={formik.touched.cutOffDate && formik.errors.cutOffDate}
            />
          </Box>
          <PrimaryTextField
            fullWidth
            variant={"outlined"}
            id={"penaltyFeesName"}
            name={"Penalty Fees Name"}
            value={formik.values.penaltyFeesName}
            onChange={(event) => formik.setFieldValue('penaltyFeesName', event.target.value)}
            onBlur={formik.handleBlur}
            error={formik.touched.penaltyFeesName && Boolean(formik.errors.penaltyFeesName)}
            helperText={formik.touched.penaltyFeesName && formik.errors.penaltyFeesName}
            select
            SelectProps={{
              native: true,
            }}
          >
            <option value={"Penalty Fees"}>
            Penalty Fees
            </option>
            <option value={"Transaction Fees"} className="flex jcfs aic g10">
            Transaction Fees
            </option>
            <option value={"Refunded Amount"} className="flex jcfs aic g10">
            Refunded Amount
            </option>
          </PrimaryTextField>
        <PrimaryTextField
            fullWidth
            variant="outlined"
            type="number"
            label={formik.values.penaltyFeesName ? formik.values.penaltyFeesName : "Fees"}
            id="penaltyFees"
            name="penaltyFees"
            value={formik.values.penaltyFees}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.penaltyFees && Boolean(formik.errors.penaltyFees)}
            helperText={formik.touched.penaltyFees && formik.errors.penaltyFees}
          />
        <PrimaryTextField
          fullWidth
          variant="outlined"
          type="text"
          id="referenceNumber"
          name="referenceNumber"
          label={"Reference Number"}
          value={formik.values.referenceNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.referenceNumber && Boolean(formik.errors.referenceNumber)}
          helperText={formik.touched.referenceNumber && formik.errors.referenceNumber}
        />
        

        <Box className={`grid jcs aic g10`}>
          <ItemsTable collectedAmount={formik.values.collectedAmount} taxInPrecent={formik.values.taxInPrecent} currency={formik.values.currency}penaltyFees={formik.values.penaltyFees} penaltyFeesName={formik.values.penaltyFeesName} attrition={formik.values.attrition} cancellation={formik.values.cancellation} cutOffDate={formik.values.cutOffDate}  />
          <SecondaryButton onClick={handleOpenAddItemModal} sx={{ width: "fit-content" }}>Add New Item</SecondaryButton>
        </Box>
      </Box>

      <LoadButton loading={loading}>
        <PrimaryButton type={"submit"} className={`flex jcc aic g5`}>
          <Typography variant="h6">Create</Typography>
        </PrimaryButton>
      </LoadButton>
    </Box>
  )
}

export default CreateInvoice