import { AppContext } from "context/AppContext"
import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryContainer } from "mui/PrimaryContainer"
import { SpecialBox } from "mui/SpecialBox"
import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import InvoiceSection from "sections/InvoiceSection/InvoiceSection"
import { getInvoice } from "store/invoiceSlice"

const Invoice = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { invoice, isLoading } = useSelector((state) => state.invoice)
  const { setItemsTableRows } = useContext(AppContext)

  useEffect(() => {
    dispatch(getInvoice({ id }))
  }, [id, dispatch])

  useEffect(() => {
    if (invoice) {
      setItemsTableRows(invoice.table)
    }
  }, [invoice])

  return (
    <SpecialBox>
      <PrimaryBox>
        <PrimaryContainer className={`grid jcs aic g50`}>
          {
            !isLoading &&
            <InvoiceSection invoice={invoice} />
          }
        </PrimaryContainer>
      </PrimaryBox>
    </SpecialBox>
  )
}

export default Invoice