import AddLog from "pages/AddLog";
import CreateInvoice from "pages/CreateInvoice";
import EditInvoice from "pages/EditInvoice";
import EditLog from "pages/EditLog";
import Hotel from "pages/Hotel";
import Invoice from "pages/Invoice";
import Invoices from "pages/Invoices";

import { Log } from "pages/Log";
import Login from "pages/Login";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Error from "./pages/Error";
import Home from "./pages/Home";
import InvoicesLogin from "pages/InvoicesLogin";
import InvoiceClient from "pages/InvoiceClient";


export const router = createBrowserRouter ([
  
  {
    
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path:"/login",
        element:<Login/>
      },
      {
        path:"/invoices/invoicesLogin",
        element:<InvoicesLogin/>
      },
      {
        path:"/log/:id",
        element:<Log/>
      },
      {
        path:"/hotel/:id",
        element:<Hotel/>
      },
      {
        path:"/add-log",
        element:<AddLog/>
      },
      {
        path:"/edit-log/:id",
        element:<EditLog/>
      },
      {
        path:"/invoices",
        element:<Invoices/>
      },
      {
        path:"/invoices/client/:id",
        element:<InvoiceClient/>
      },
      {
        path:"/invoices/invoice/:id",
        element:<Invoice/>
      },
      
      {
        path:"/invoices/create-invoice",
        element:<CreateInvoice/>
      },
      {
        path:"/invoices/edit-invoice/:id",
        element:<EditInvoice/>
      },
      {path:"*",element:<Error/>}
    ]
  }
])

