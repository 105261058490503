import React, { useState } from "react";
import { EditRounded, RemoveRedEyeRounded, FileCopyRounded } from "@mui/icons-material";
import { Box, Paper, Typography, Button } from "@mui/material";
import { PrimaryButton } from "mui/PrimaryButton";
import { SecondaryButton } from "mui/SecondaryButton";
import { ThirdaryButton } from "mui/ThirdaryButton";
import { Link } from "react-router-dom";
import DuplicatePopup from "../../modals/DuplicatePopup"; // Import the popup component
import styles from "./Invoice.module.scss";
import { handleAlert } from '../../functions/handleAlert';

const Invoice = ({ invoice, index }) => {
  const { email, invoiceNumber, referenceNumber } = invoice;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDuplicate = async (data) => {
    try {
     
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/duplicateForm/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ referenceNumber: data.referenceNumber, invoiceNumber: data.invoiceNumber, oldInvoiceNumber: invoiceNumber }),
      });
      if (response.ok) {
        // Handle successful duplication, e.g., refresh the page
        window.location.reload();
      } else {
        handleAlert("Invoice or Reference already exists", "error");
      }
    } catch (error) {
      handleAlert("Invoice or Reference Number already exists", "error");
    } finally {
      handleClose();
    }
  };

  return (
    <Paper className={`grid jcs aic pad20 g30 br10 ${styles.invoice}`}>
      <Box className={`grid jcs aic g20`}>
        
        <Box className={`flex jcsb aic g20`}>
        <Box className={`flex jcfs aic g10 flex_wrap`}>
          <Typography variant="h6" className="fw700">Invoice # : </Typography>
          <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} className="main fw700">{invoiceNumber}</Typography>
          
        </Box>
        <ThirdaryButton  className={`flex jcc aic g10`} onClick={handleOpen}>
          <FileCopyRounded sx={{ color: (theme) => theme.palette.white }} />
          <Typography variant="h6" className="fw700">Duplicate</Typography>
        </ThirdaryButton>
        </Box>
        
        <Box className={`flex jcfs aic g10 flex_wrap`}>
          <Typography variant="h6" className="fw700">Reference Number : </Typography>
          <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} className="main fw700">{referenceNumber}</Typography>
        </Box>
        <Box className={`flex jcfs aic g10 flex_wrap`}>
          <Typography variant="h6" className="fw700">Customer Email : </Typography>
          <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }} className="main fw700">{email[0]}</Typography>
        </Box>
      </Box>
      <Box className={`flex jcsb aic g20`}>
        <Link to={`${process.env.REACT_APP_EDIT_INVOICE_ROUTE}/${invoice._id}`}>
          <SecondaryButton fullWidth className={`flex jcc aic g10`}>
            <EditRounded sx={{ color: (theme) => theme.palette.primary.main }} />
            <Typography variant="h6" className="fw700">Edit</Typography>
          </SecondaryButton>
        </Link>
        <Link to={`${process.env.REACT_APP_INVOICE_ROUTE}/${invoice._id}`}>
          <PrimaryButton fullWidth className={`flex jcc aic g10`}>
            <RemoveRedEyeRounded sx={{ color: (theme) => theme.palette.white }} />
            <Typography variant="h6" className="fw700">View</Typography>
          </PrimaryButton>
        </Link>
        
      </Box>
      <DuplicatePopup open={open} handleClose={handleClose} handleDuplicate={handleDuplicate} />
    </Paper>
  );
};

export default Invoice;
