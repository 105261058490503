import Forms from "forms/Forms"
import { SpecialBox } from "mui/SpecialBox"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { getLog } from "store/logSlice"

const EditLog = () => {
  const dispatch = useDispatch()
  const {id} = useParams()

  useEffect(()=>{
    dispatch(getLog({id}))
  },[dispatch,id])
  
  return (
    <SpecialBox>
      <Forms type={"edit_log"}/>
    </SpecialBox>
  )
}

export default EditLog