import Forms from "forms/Forms"
import { PrimaryBox } from "mui/PrimaryBox"
import { SpecialBox } from "mui/SpecialBox"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import InvoicesSection from "sections/InvoicesSection/InvoicesSection"
import { getInvoices } from "store/invoicesSlice"

const Invoices = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInvoices({ count: 0, search: "" }))
  }, [dispatch])
  return (
    <SpecialBox >
      <PrimaryBox className="grid jcs aic g30">
        <Forms type={"search_for_invoices"} />
        <InvoicesSection />
      </PrimaryBox>
    </SpecialBox>
  )
}

export default Invoices