import React from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from "@mui/material";

const VoucherPopup = ({ open, handleClose, handleVoucher }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    handleVoucher(data);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Download Credit Voucher</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            margin="dense"
            label="Details"
            fullWidth
            {...register("details", { required: "Details are required" })}
            error={!!errors.details}
            helperText={errors.details?.message}
          />
          <TextField
            margin="dense"
            label="Valid Until"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register("validUntil", { required: "Valid Until date is required" })}
            error={!!errors.validUntil}
            helperText={errors.validUntil?.message}
          />
          <TextField
            margin="dense"
            label="Amount"
            fullWidth
            {...register("amount", { required: "Amount is required" })}
            error={!!errors.amount}
            helperText={errors.amount?.message}
          />
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            <Button type="submit" color="primary">Submit</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default VoucherPopup;
