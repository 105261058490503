import { Box, Typography } from "@mui/material"
import Invoice from "components/Invoice/Invoice"
import LoadingInvoice from "components/Invoice/LoadingInvoice"
import Paginate from "components/Paginate/Paginate"
import { useSelector } from "react-redux"
import { PrimaryContainer } from "../../mui/PrimaryContainer"
import styles from "./InvoicesSection.module.scss"

const InvoicesSection = () => {
  const { invoices, totalInvoices, isLoading } = useSelector((state) => state.invoices)
  return (
    <PrimaryContainer className={`grid jcs aifs g50`}>
      <Box className={`grid jcs aifs g30 ${styles.invoices_section}`}>
        {
          isLoading ? (new Array(Math.floor(Math.random() * 6) + 5).fill(0).map((_, i) => (
            <LoadingInvoice key={i} />
          ))) : invoices && invoices.length > 0 ?
            invoices.map((invoice, i) => (
              <Invoice invoice={invoice} key={i} index={i} />
            ))
            : (<Typography variant="h4" className={`tac`} sx={{ color: (theme) => theme.palette.gray }}>No Invoices Yet...</Typography>)
        }
      </Box>
      {
        totalInvoices > 10 && <Paginate variant={"invoices"} count={Math.ceil(totalInvoices / 10)} />
      }
    </PrimaryContainer>
  )
}

export default InvoicesSection