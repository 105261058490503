import { Paper, Typography } from "@mui/material"

const InvoiceBoxData = ({ k, val }) => {
  return (
    <Paper className="pad15 flex flex_wrap jcfs aic g5" sx={{ width: "100%" }}>
      <Typography variant={"h6"}>{k} : </Typography>
      <Typography variant={"h6"} className="main_color fw700">{val}</Typography>
    </Paper>
  )
}

export default InvoiceBoxData