import { Box, Skeleton, Typography } from "@mui/material"
import LoadingLogButton from "components/LogButtons/LoadingLogButton"
import LogButtons from "components/LogButtons/LogButtons"
import LoadingLogHotels from "components/LogHotels/LoadingLogHotels"
import { LogHotels } from "components/LogHotels/LogHotels"
import LoadingLogInfo from "components/LogInfo/LoadingLogInfo"
import LogInfo from "components/LogInfo/LogInfo"
import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryContainer } from "mui/PrimaryContainer"
import { SpecialBox } from "mui/SpecialBox"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getLog } from "store/logSlice"

export const Log = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { log, isLoading } = useSelector((state) => state.log)

  useEffect(() => {
    dispatch(getLog({ id }))
  }, [id, dispatch])

  return (
    <SpecialBox>
      <PrimaryBox>
        <PrimaryContainer className={`grid jcs aic g50`}>
          {
            isLoading ?
              (<>
                <Skeleton sx={{ width: "75%", height: "20px" }} className="center_rel_x" variant="text" />
                <Box className={`grid jcs aic g40`}>
                  <LoadingLogHotels />
                  <LoadingLogInfo />
                  <LoadingLogButton />
                </Box>
              </>) :
              (
                <>
                  <Typography variant="h4" className={`tac fw700`} sx={{ color: (theme) => theme.palette.primary.main }}>{log.Name}</Typography>
                  <Box className={`grid jcs aic g30`}>
                    <LogHotels hotels={log.Hotels} />
                    <LogInfo log={log} />
                    <LogButtons log={log} />
                  </Box>
                </>
              )
          }
        </PrimaryContainer>
      </PrimaryBox>
    </SpecialBox>
  )
}
