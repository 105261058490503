import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'

export const getInvoiceClient = createAsyncThunk("invoice/getInvoice",async(args)=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_COOKIE_NAME}`)
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/clientInvoice/${args.id}`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  
  return res.data.data
}) 

const initialState = {
  invoiceClient:null,
  isLoading:true
}

export const invoiceClientSlice = createSlice({
  name: 'invoiceClient',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoiceClient.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getInvoiceClient.fulfilled, (state, { payload }) => {
      state.invoice = payload
      state.isLoading = false
    })
    builder.addCase(getInvoiceClient.rejected, (state) => {
      state.isLoading = true
    })
  },
})

export default invoiceClientSlice.reducer