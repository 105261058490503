import { Box, Typography } from "@mui/material"
import { PrimaryBox } from "mui/PrimaryBox"
import { PrimaryContainer } from "mui/PrimaryContainer"
import { LazyLoadImage } from "react-lazy-load-image-component"
import star from "../../assets/images/star.png"
import styles from "./HotelCoverSection.module.scss"

export const HotelCoverSection = ({ hotel }) => {
  return (
    <PrimaryBox className={`relative grid jcs aic g30 ${styles.cover_section_bg}`} sx={{ backgroundImage: `url(${hotel.photos[0]})` }}>
      <PrimaryContainer>
        <PrimaryBox className={`grid jcc aic g30 ${styles.cover_section}`}>
          <Box className={`overlay`} />
          <Box className={`flex jcc aic flex_wrap g10 ${styles.stars}`}>
            {new Array(hotel.star_rating).fill(0).map((_, i) => (
              <LazyLoadImage key={i} src={star} alt={"star"} />
            ))}
          </Box>
          <Typography className={`tac ${styles.up} fw800`} variant="h4">{hotel.name}</Typography>
          <Typography className={`tac ${styles.up}`} variant="h6">{hotel.address}</Typography>
        </PrimaryBox>
      </PrimaryContainer >
    </PrimaryBox >
  )
}
