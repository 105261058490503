import { DoorBackRounded, EditRounded, LocalActivityRounded, MailRounded } from "@mui/icons-material";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";

import { Box, Typography } from "@mui/material";
import { AppContext } from "context/AppContext";
import { PrimaryButton } from "mui/PrimaryButton";
import { SecondaryButton } from "mui/SecondaryButton";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LogButtons.module.scss";

const LogButtons = ({ log }) => {
  const navigate = useNavigate()
  const { handleOpenDownloadLogModal, handleOpenSendMailModal, handleOpenViewActivitiesModal, handleOpenViewMailOpensModal } = useContext(AppContext)

  const handleEdit = () => {
    navigate(`${process.env.REACT_APP_EDIT_LOG_ROUTE}/${log._id}`)
  }

  // const handleDelete=()=>{
  //   handleOpenDeleteLogModal(log._id)
  // }


  const handleDownloadPDF = () => {
    handleOpenDownloadLogModal(log._id)
  }

  const handleSendMail = () => {
    handleOpenSendMailModal(log._id)
  }

  const handleViewActivities = () => {
    handleOpenViewActivitiesModal(log.Actions)
  }

  const handleViewMailOpens = () => {
    handleOpenViewMailOpensModal(log?.MailOpened)
  }

  return (
    <Box className={`grid jcs aic g20`}>
      <Box className={`grid jcs aic g30 ${styles.log_buttons}`}>
        <PrimaryButton onClick={handleEdit} className="flex jcc aic g5">
          <EditRounded />
          <Typography variant="h6">Edit</Typography>
        </PrimaryButton>
        <SecondaryButton onClick={handleDownloadPDF} className="flex jcc aic g5">
          <BsFillFileEarmarkPdfFill />
          <Typography variant="h6">Download PDF</Typography>
        </SecondaryButton>
        <SecondaryButton onClick={handleSendMail} className="flex jcc aic g5">
          <MailRounded />
          <Typography variant="h6" >Send Mail</Typography>
        </SecondaryButton>
        {/* <DeleteButton onClick={handleDelete} className="flex jcc aic g5">
        <DeleteRounded/>
        <Typography variant="h6">Delete</Typography>
      </DeleteButton> */}
      </Box>
      <Box className={`flex jcsb aic g30`}>
        <PrimaryButton fullWidth onClick={handleViewActivities} className="flex jcc aic g5">
          <LocalActivityRounded />
          <Typography variant="h6">Activities</Typography>
        </PrimaryButton>
        <PrimaryButton fullWidth onClick={handleViewMailOpens} className="flex jcc aic g5">
          <DoorBackRounded />
          <Typography variant="h6">Tracking</Typography>
        </PrimaryButton>
      </Box>
    </Box>
  )
}

export default LogButtons